import { config } from 'Shared/Services/environment.service';
import { CookieSerializeOptions, parse } from 'cookie';
import { GetServerSidePropsContext } from 'next';

export class LoopError extends Error {
  public data: any;

  constructor(message: string, data: any) {
    super(message);
    this.data = data;
  }
}

type CookieOptions = { name: string } & Pick<
  CookieSerializeOptions,
  'domain' | 'secure' | 'path' | 'sameSite' | 'maxAge'
>;

export type Session = {
  accessToken: {
    value: string;
    iat: number;
    exp: number;
  };
  refreshToken: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
};

export function createLoopClient({
  fetchSession,
}: {
  fetchSession?: () => Session | null;
} = {}) {
  const _request = async (path: string, init?: RequestInit | undefined) => {
    const baseUrl = config('NX_NEXT_PUBLIC_API_URL');

    const res = await fetch(`${baseUrl}${path}`, {
      ...init,
      method: 'POST',
      headers: {
        ...init?.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!res.ok) {
      throw new LoopError(`Error during _request: ${path}`, await res.json());
    }

    return res.json();
  };

  const confirmRegistration = async ({
    email,
    verificationCode,
  }: {
    email: string;
    verificationCode: string;
  }) => {
    return _request('/v2/cognito/confirm-registration', {
      body: JSON.stringify({
        email,
        verificationCode,
      }),
    });
  };

  const resendConfirmationCode = async (email: string) => {
    return _request('/v2/cognito/resend-confirmation-code', {
      body: JSON.stringify({ email }),
    });
  };

  const forgotPassword = async (email: string) => {
    return _request('/v2/cognito/forgot-password', {
      body: JSON.stringify({ email }),
    });
  };

  const confirmPassword = async ({
    email,
    verificationCode,
    password,
  }: {
    email: string;
    verificationCode: string;
    password: string;
  }) => {
    return _request('/v2/cognito/confirm-password', {
      body: JSON.stringify({ email, verificationCode, password }),
    });
  };

  const signUp = async ({
    attributes,
    password,
  }: {
    attributes: {
      givenName: string;
      familyName: string;
      phoneNumber: string;
      privacy: boolean;
      language: string;
      email: string;
    };
    password: string;
  }) => {
    return _request('/v2/cognito/signup', {
      body: JSON.stringify({
        attributes,
        password,
      }),
    });
  };

  return {
    signUp,
    confirmPassword,
    confirmRegistration,
    forgotPassword,
    resendConfirmationCode,
    fetchSession,
  };
}

export function createLoopBrowserClient() {
  return createLoopClient();
}

export function createLoopServerClient(
  context: GetServerSidePropsContext,
  {
    cookieOptions = {
      name: 'kaaja-cookie',
      domain: '',
      path: '/',
      maxAge: 1000 * 60 * 60 * 24 * 365,
      sameSite: 'lax',
      secure: undefined,
    },
  }: {
    cookieOptions?: CookieOptions;
  } = {}
) {
  return createLoopClient({
    fetchSession: () => {
      const cookies = parse(context.req.headers.cookie || '');

      if (!cookies[cookieOptions.name]) {
        return null;
      }

      try {
        return JSON.parse(cookies[cookieOptions.name]) as Session;
      } catch {
        return null;
      }
    },
  });
}
