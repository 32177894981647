import type { Output } from 'valibot';
import { enumType, object, parse, string } from 'valibot';

const EnvSchema = object({
  NX_NEXT_PUBLIC_STRIPE_PUBLIC_KEY: string(),
  NX_NEXT_PUBLIC_S3_HOST: string(),
  NX_NEXT_PUBLIC_MERCURE_HOST: string(),
  NX_NEXT_PUBLIC_MERCURE_TOKEN: string(),
  NX_NEXT_PUBLIC_GOOGLE_MAPS_KEY: string(),
  NX_NEXT_PUBLIC_GTM_ID: string(),
  NX_NEXT_PUBLIC_FACEBOOK_PIXEL_ID: string(),
  NX_NEXT_PUBLIC_FRONTEND_HOST: string(),
  NX_NEXT_PUBLIC_HUBSPOT_PORTAL_ID: string(),
  NX_NEXT_PUBLIC_KAAJA_API_KEY: string(),
  NX_NEXT_PUBLIC_API_URL: string(),
  NX_NEXT_MS_AUTH_TOKEN: string(),
  NX_NEXT_PUBLIC_ENV: enumType(['prd', 'demo']),
});

export type Env = Output<typeof EnvSchema>;

const result = parse(EnvSchema, {
  NX_NEXT_PUBLIC_STRIPE_PUBLIC_KEY:
    process.env.NX_NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  NX_NEXT_PUBLIC_S3_HOST: process.env.NX_NEXT_PUBLIC_S3_HOST,
  NX_NEXT_PUBLIC_MERCURE_HOST: process.env.NX_NEXT_PUBLIC_MERCURE_HOST,
  NX_NEXT_PUBLIC_MERCURE_TOKEN: process.env.NX_NEXT_PUBLIC_MERCURE_TOKEN,
  NX_NEXT_PUBLIC_GOOGLE_MAPS_KEY: process.env.NX_NEXT_PUBLIC_GOOGLE_MAPS_KEY,
  NX_NEXT_PUBLIC_GTM_ID: process.env.NX_NEXT_PUBLIC_GTM_ID,
  NX_NEXT_PUBLIC_FACEBOOK_PIXEL_ID:
    process.env.NX_NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  NX_NEXT_PUBLIC_FRONTEND_HOST: process.env.NX_NEXT_PUBLIC_FRONTEND_HOST,
  NX_NEXT_PUBLIC_HUBSPOT_PORTAL_ID:
    process.env.NX_NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
  NX_NEXT_PUBLIC_KAAJA_API_KEY: process.env.NX_NEXT_PUBLIC_KAAJA_API_KEY,
  NX_NEXT_PUBLIC_API_URL: process.env.NX_NEXT_PUBLIC_API_URL,
  NX_NEXT_PUBLIC_ENV: process.env.NX_NEXT_PUBLIC_ENV,
  NX_NEXT_MS_AUTH_TOKEN: process.env.NX_NEXT_MS_AUTH_TOKEN,
});

export function config(string: keyof Env) {
  return result[string];
}
