import { createContext, ReactNode, useContext } from 'react';

const IntlContext = createContext<{ common: any; route: any; __: any } | null>(null);

export function IntlProvider({
  common,
  route,
  children,
}: {
  common: any;
  route: any;
  children: ReactNode;
}) {
  const __ = (ns: string, vars: Record<string, string> = {}) => {
    const createTranslated = (nodes: string[], vars: Record<string, string>, data: any): string => {
      const [current, ...restKeys] = nodes;
      const value = data[current];

      if (value === undefined) {
        return ns;
      }

      if (restKeys.length === 0 && typeof value === 'string') {
        let final = value;

        for (const item in vars) {
          final = final.replace(`{${item}}`, vars[item]);
        }

        return final;
      }

      return createTranslated(restKeys, vars, value);
    };

    return createTranslated(ns.split('.'), vars, { ...common, ...route }); // Works only with common.
  };

  return <IntlContext.Provider value={{ common, route, __ }}>{children}</IntlContext.Provider>;
}

export function useIntl() {
  const context = useContext(IntlContext);

  if (!context) {
    throw new Error('No IntlProvider.');
  }

  return context;
}
