import axios, { AxiosRequestConfig } from 'axios';
import { config } from './environment.service';
import { getLang } from './i18n.service';

export function createHttpClient(config?: AxiosRequestConfig) {
  return axios.create(config);
}

export const client = createHttpClient({
  baseURL: config('NX_NEXT_PUBLIC_API_URL'),
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(
  (config) => {
    config.headers['X-User-Locale'] = getLang();
    config.headers['Accept-Language'] = getLang();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
