import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IntlProvider } from 'Features/Intl/context';
import { DepsProvider } from 'Shared/Application/DepsProvider';
import { client as HTTPClient } from 'Shared/Services/http.service';
import { HubspotService } from 'Shared/Services/hubspot.service';
import { useLang } from 'Shared/Services/i18n.service';
import { KaajaProvider } from 'TBM/OldSharedUI/theme';
import { createLoopBrowserClient } from 'loop/client';
import { LoopProvider } from 'loop/context';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { Toaster } from 'sonner';

import '../styles/index.scss';
import '../styles/tailwind.css';

const queryClient = new QueryClient();
const client = createLoopBrowserClient();
const hubspot = new HubspotService(HTTPClient);

const MyApp = ({ Component, pageProps }: AppProps) => {
  useLang();

  return (
    <IntlProvider {...pageProps.intl}>
      <DepsProvider hubspot={hubspot}>
        <QueryClientProvider client={queryClient}>
          <LoopProvider client={client}>
            <KaajaProvider>
              <DefaultSeo
                openGraph={{
                  type: 'website',
                  siteName: 'Kaaja',
                  url: 'https://www.kaaja.com',
                }}
              />

              <Component {...pageProps} />
            </KaajaProvider>
          </LoopProvider>
        </QueryClientProvider>

        <Toaster position="bottom-left" />
      </DepsProvider>
    </IntlProvider>
  );
};

export default appWithTranslation(MyApp);
