import { HubspotService } from 'Shared/Services/hubspot.service';
import * as React from 'react';

type DepsContextProps = {
  hubspot: HubspotService;
};

const DepsContext = React.createContext<DepsContextProps | null>(null);

export function useDeps() {
  const ctx = React.useContext(DepsContext);

  if (!ctx) throw new Error('Be sure to use useDeps inside a DepsProvider.');

  return ctx;
}

export function DepsProvider({
  children,
  ...services
}: DepsContextProps & { children: React.ReactNode }) {
  return (
    <DepsContext.Provider value={services}>{children}</DepsContext.Provider>
  );
}
