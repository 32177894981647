import { config } from 'Shared/Services/environment.service';
import { CookieSerializeOptions, parse, serialize } from 'cookie';
import * as React from 'react';
import { createLoopBrowserClient } from './client';

export type Session = {
  accessToken: {
    value: string;
    iat: number;
    exp: number;
  };
  refreshToken: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
};

/** Cookie Management */

const defaultCookieOptions: CookieOptions = {
  name: 'kaaja-cookie',
  domain: '',
  path: '/',
  maxAge: 1000 * 60 * 60 * 24 * 365,
  sameSite: 'lax',
  secure: undefined,
};

type CookieOptions = { name: string } & Pick<
  CookieSerializeOptions,
  'domain' | 'secure' | 'path' | 'sameSite' | 'maxAge'
>;

export function createCookie(content: string, cookieOptions: CookieOptions) {
  document.cookie = serialize(cookieOptions.name, content, {
    domain: window.location.hostname,
    path: cookieOptions.path,
    maxAge: cookieOptions.maxAge,
    httpOnly: false, // Needed to let loop read cookie.
    sameSite: cookieOptions.sameSite,
    secure: cookieOptions.secure ?? document.location?.protocol === 'https:',
  });
}

export function createSessionFromCookie() {
  const cookies = parse(document.cookie);

  if (!cookies[defaultCookieOptions.name]) {
    return null;
  }

  try {
    return JSON.parse(cookies[defaultCookieOptions.name]) as Session;
  } catch {
    return null;
  }
}

/** LoopContext */

type LoopContextProps = {
  client: ReturnType<typeof createLoopBrowserClient>;
  signIn: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<Session>;
  signOut: ({ email }: { email: string }) => Promise<void>;
  session: Session | null;
};

export const LoopContext = React.createContext<LoopContextProps | null>(null);

export function LoopProvider({
  children,
  client,
}: {
  children: React.ReactNode;
  client: ReturnType<typeof createLoopBrowserClient>;
}) {
  const [session, setSession] = React.useState<Session | null>(null);

  /** Fetching Session at startup */
  React.useEffect(() => {
    if (session) {
      console.log('AUTH: Sessione già esistente', session);
    } else {
      console.log('AUTH: Sessione non esistente, estrazione cookie...');
      const cookieSession = createSessionFromCookie();
      setSession(cookieSession);
    }
  }, []);

  /** Abstraction over Fetch */
  const _request = async (path: string, init?: RequestInit | undefined) => {
    const baseUrl = config('NX_NEXT_PUBLIC_API_URL');

    const res = await fetch(`${baseUrl}${path}`, {
      ...init,
      method: 'POST',
      headers: {
        ...init?.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!res.ok) {
      throw new Error(
        `LoopRequest Error during _request: ${path}: ${await res.text()}`
      );
    }

    return res.json();
  };

  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const data = await _request('/v2/cognito/signin', {
      body: JSON.stringify({
        email,
        password,
      }),
    });

    createCookie(JSON.stringify(data), {
      ...defaultCookieOptions,
      maxAge: data.accessToken.exp - data.accessToken.iat,
    });

    setSession(data);

    return data as Session;
  };

  const signOut = async ({ email }: { email: string }) => {
    await _request('/v2/cognito/signout', {
      body: JSON.stringify({
        email,
      }),
      headers: {
        Authorization: `Bearer ${session?.accessToken.value}`,
      },
    });

    /** Invalidate cookie */
    createCookie('', {
      ...defaultCookieOptions,
      maxAge: 0,
    });

    setSession(null);
  };

  return (
    <LoopContext.Provider value={{ client, signIn, signOut, session }}>
      {children}
    </LoopContext.Provider>
  );
}

export function useLoop() {
  const ctx = React.useContext(LoopContext);

  if (!ctx) {
    throw new Error('useLoop must be used inside AuthContext');
  }

  return ctx;
}
