import * as HSTypes from 'Shared/Types/hubspot.types';
import { AxiosInstance } from 'axios';

export class HubspotService {
  client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async createBookingFormRecord(data: HSTypes.CreateBookingFormRecord) {
    return this.client.post('/v2/hubspot/forms/booking', data);
  }

  async createFooterFormRecord(data: HSTypes.CreateFooterFormRecord) {
    return this.client.post('/v2/hubspot/forms/footer', data);
  }

  async createEstimatorFormRecord(data: HSTypes.CreateEstimatorFormRecord) {
    return this.client.post('/v2/hubspot/forms/estimator', data);
  }

  async createContactFormRecord(data: HSTypes.CreateContactFormRecord) {
    return this.client.post('/v2/hubspot/forms/contact', data);
  }

  async createBuyNowFormRecord(data: HSTypes.CreateBuyNowFormRecord) {
    return this.client.post('/v2/hubspot/forms/buynow', data);
  }

  async createSignUpFormRecord(data: HSTypes.CreateSignUpFormRecord) {
    return this.client.post('/v2/hubspot/forms/sign-up', data);
  }

  async createFeedbackFormRecord(data: HSTypes.CreateFeedbackFormRecord) {
    return this.client.post('/v2/hubspot/forms/customer-feedback', {
      email: data.email,
      feedback_cliente__score_agente: data.agentScore,
      feedback_cliente__score_immobile: data.propertyScore,
      feedback_cliente__score_note: data.notes,
      consentToProcess: data.consentToProcess,
      consentToMarketing: data.consentToMarketing,
      consentToArt11: data.consentToArt11,
    });
  }

  async createDealFavorite(data: HSTypes.CreateDealFavorite) {
    return this.client.post('/v2/hubspot/deals/favorite', data);
  }

  async createDealEstimation(data: HSTypes.CreateDealEstimation) {
    return this.client.post('/v2/hubspot/deals/estimation', data);
  }

  async createDealBookingRequest(data: HSTypes.CreateDealBookingRequest) {
    return this.client.post('/v2/hubspot/deals/booking-request', data);
  }

  async createDealBid(data: HSTypes.CreateDealBid) {
    return this.client.post('/v2/hubspot/deals/bid', data);
  }

  async createDealBooking(data: HSTypes.CreateDealBooking) {
    return this.client.post('/v2/hubspot/deals/booking', data);
  }

  async deleteDealBooking(data: HSTypes.DeleteDealBooking) {
    return this.client.put('/v2/hubspot/deals/booking', data);
  }

  async createSubscription(data: HSTypes.CreateSubscription) {
    return this.client.post('/v2/hubspot/subscribe', data);
  }

  /** Hello */
  async createFile(file: File) {
    const formData = new FormData();
    formData.append('document', file);
    return this.client.post('/v2/hubspot/files', formData);
  }
}
