import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  breakpoints: ['550px', '1100px', '1500px'],
  colors: {
    white: '#ffffff',
    black: '#000000',
    neutral50: '#f5f5ff',
    neutral100: '#ebebf5',
    neutral200: '#dddde7',
    neutral300: '#c9c9d3',
    neutral500: '#8C8A9F',
    neutral600: '#6F6E75',
    neutral700: '#515151',
    neutral900: '#131223',
    feedbackSuccess: '#006531',
    feedbackWarning: '#BE5409',
    feedbackError: '#C61811',
    blueLight500: '#3E3AE8',
    blueLight700: '#2824E5',
    blueDark800: '#3E3391',
    blueDark900: '#0D0068',
    pinkAccent300: '#DD3C9D',
    pinkAccent400: '#D92792',
  },
  space: [
    0, // 0
    '0.125rem', // 2px 1
    '0.25rem', // 4px 2
    '0.5rem', // 8px 3
    '0.75rem', // 12px 4
    '1rem', // 16px 5
    '2rem', // 32px 6
    '4rem', // 64px 7
    '8rem', // 128px 8
    '16rem', // 256px 9
  ],
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Space Grotesk, sans-serif',
  },
  fontSizes: [
    '0.75rem', // 12px 0
    '0.875rem', // 14px 1
    '1rem', // 16px 2
    '1.25rem', // 20px 3
    '1.5rem', // 24px 4
    '2rem', // 32px 5
    '3rem', // 48px 6
    '4rem', // 64px 7
  ],
  borders: {
    none: 'none',
    thin: '1px solid',
  },
  radii: {
    none: 0,
    base: '4px',
    round: '99999em',
  },
  shadows: {
    small:
      '-2px -2px 6px rgba(40, 36, 229, 0.05), 2px 2px 6px rgba(40, 36, 229, 0.05)',
    medium:
      '-4px -4px 6px rgba(40, 36, 229, 0.05), 4px 4px 6px rgba(40, 36, 229, 0.05)',
  },
  lineHeights: {
    none: 1,
    tight: 1.25,
    snug: 1.375,
    normal: 1.6,
    relaxed: 1.625,
    loose: 2,
  },
  zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    toast: 1700,
    tooltip: 1800,
  },
};
