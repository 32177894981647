import cookie from 'cookie';
import { useRouter } from 'next/router';
import * as React from 'react';

export function createSafeLang(lang: string | undefined) {
  if (lang && (lang.startsWith('it') || lang.startsWith('en'))) {
    return lang;
  }

  return 'it-IT';
}

export function useLang() {
  const { locale = 'it' } = useRouter();
  const [selected, setSelected] = React.useState(createSafeLang(locale));

  React.useEffect(() => {
    document.cookie = `NEXT_LOCALE=${selected}; maxage=${
      1000 * 60 * 60 * 24 * 7
    }; path=/`;
  }, [selected]);
}

export function getLang() {
  const cookies = cookie.parse(document.cookie);
  return createSafeLang(cookies.NEXT_LOCALE);
}
